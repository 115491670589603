// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-case-studies-js": () => import("./../../../src/pages/about/case-studies.js" /* webpackChunkName: "component---src-pages-about-case-studies-js" */),
  "component---src-pages-about-company-js": () => import("./../../../src/pages/about/company.js" /* webpackChunkName: "component---src-pages-about-company-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-customer-stories-audit-product-community-cloud-js": () => import("./../../../src/pages/customer-stories/audit-product-community-cloud.js" /* webpackChunkName: "component---src-pages-customer-stories-audit-product-community-cloud-js" */),
  "component---src-pages-customer-stories-b-2-b-commerce-cloud-pharma-js": () => import("./../../../src/pages/customer-stories/b2b-commerce-cloud-pharma.js" /* webpackChunkName: "component---src-pages-customer-stories-b-2-b-commerce-cloud-pharma-js" */),
  "component---src-pages-customer-stories-fintech-community-cloud-js": () => import("./../../../src/pages/customer-stories/fintech-community-cloud.js" /* webpackChunkName: "component---src-pages-customer-stories-fintech-community-cloud-js" */),
  "component---src-pages-customer-stories-salesforce-digital-marketing-usa-js": () => import("./../../../src/pages/customer-stories/salesforce-digital-marketing-usa.js" /* webpackChunkName: "component---src-pages-customer-stories-salesforce-digital-marketing-usa-js" */),
  "component---src-pages-customer-stories-salesforce-nonprofit-case-study-usa-js": () => import("./../../../src/pages/customer-stories/salesforce-nonprofit-case-study-usa.js" /* webpackChunkName: "component---src-pages-customer-stories-salesforce-nonprofit-case-study-usa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infographics-js": () => import("./../../../src/pages/infographics.js" /* webpackChunkName: "component---src-pages-infographics-js" */),
  "component---src-pages-infographics-salesforce-report-indian-customers-js": () => import("./../../../src/pages/infographics/salesforce-report-indian-customers.js" /* webpackChunkName: "component---src-pages-infographics-salesforce-report-indian-customers-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-data-analytics-js": () => import("./../../../src/pages/services/data-analytics.js" /* webpackChunkName: "component---src-pages-services-data-analytics-js" */),
  "component---src-pages-services-fintech-js": () => import("./../../../src/pages/services/fintech.js" /* webpackChunkName: "component---src-pages-services-fintech-js" */),
  "component---src-pages-services-it-security-js": () => import("./../../../src/pages/services/it-security.js" /* webpackChunkName: "component---src-pages-services-it-security-js" */),
  "component---src-pages-services-looker-consulting-js": () => import("./../../../src/pages/services/looker-consulting.js" /* webpackChunkName: "component---src-pages-services-looker-consulting-js" */),
  "component---src-pages-services-marketing-automation-for-ecommerce-js": () => import("./../../../src/pages/services/marketing-automation-for-ecommerce.js" /* webpackChunkName: "component---src-pages-services-marketing-automation-for-ecommerce-js" */),
  "component---src-pages-services-marketing-automation-services-js": () => import("./../../../src/pages/services/marketing-automation-services.js" /* webpackChunkName: "component---src-pages-services-marketing-automation-services-js" */),
  "component---src-pages-services-mulesoft-consulting-js": () => import("./../../../src/pages/services/mulesoft-consulting.js" /* webpackChunkName: "component---src-pages-services-mulesoft-consulting-js" */),
  "component---src-pages-services-procurement-consulting-js": () => import("./../../../src/pages/services/procurement-consulting.js" /* webpackChunkName: "component---src-pages-services-procurement-consulting-js" */),
  "component---src-pages-services-salesforce-certification-training-js": () => import("./../../../src/pages/services/salesforce-certification-training.js" /* webpackChunkName: "component---src-pages-services-salesforce-certification-training-js" */),
  "component---src-pages-services-salesforce-consulting-js": () => import("./../../../src/pages/services/salesforce-consulting.js" /* webpackChunkName: "component---src-pages-services-salesforce-consulting-js" */),
  "component---src-pages-services-salesforce-customization-services-js": () => import("./../../../src/pages/services/salesforce-customization-services.js" /* webpackChunkName: "component---src-pages-services-salesforce-customization-services-js" */),
  "component---src-pages-services-salesforce-development-services-js": () => import("./../../../src/pages/services/salesforce-development-services.js" /* webpackChunkName: "component---src-pages-services-salesforce-development-services-js" */),
  "component---src-pages-services-salesforce-implementation-services-js": () => import("./../../../src/pages/services/salesforce-implementation-services.js" /* webpackChunkName: "component---src-pages-services-salesforce-implementation-services-js" */),
  "component---src-pages-services-salesforce-maintenance-support-services-js": () => import("./../../../src/pages/services/salesforce-maintenance-support-services.js" /* webpackChunkName: "component---src-pages-services-salesforce-maintenance-support-services-js" */),
  "component---src-pages-services-salesforce-training-js": () => import("./../../../src/pages/services/salesforce-training.js" /* webpackChunkName: "component---src-pages-services-salesforce-training-js" */),
  "component---src-pages-services-sdfc-integration-services-js": () => import("./../../../src/pages/services/sdfc-integration-services.js" /* webpackChunkName: "component---src-pages-services-sdfc-integration-services-js" */),
  "component---src-pages-services-spend-analysis-js": () => import("./../../../src/pages/services/spend-analysis.js" /* webpackChunkName: "component---src-pages-services-spend-analysis-js" */),
  "component---src-pages-services-staff-augmentation-services-js": () => import("./../../../src/pages/services/staff-augmentation-services.js" /* webpackChunkName: "component---src-pages-services-staff-augmentation-services-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/Thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-thankyou-page-js": () => import("./../../../src/pages/thankyou_Page.js" /* webpackChunkName: "component---src-pages-thankyou-page-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-webinars-marketing-automation-roi-webinar-js": () => import("./../../../src/pages/webinars/marketing-automation-roi-webinar.js" /* webpackChunkName: "component---src-pages-webinars-marketing-automation-roi-webinar-js" */)
}

